import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Icon, Label, Grid, Button, Modal, Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { SubHeader, UserInput } from "./../../components";
import { setAlert } from "../App/store";
import Detail from "./Detail";
import Can from "../Can";

import Service from "./service";
import CustomConfirm from "../../components/CustomConfirm";
import "./ChecklistSettings.css";

/*
    ChecklistSettings
*/
class ChecklistSettings extends Component {
  state = {
    checklistTemplates: [],
    isLoadingChecklistTemplates: false,
    isDeletingChecklistTemplate: false,

    reportTemplates: [],
    isLoadingReportTemplates: false,

    isNewChecklistTemplateVisible: false,
    newChecklistTemplateName: "",
    submitting: false,

    selectedChecklistTemplate: {},

    showError: false,

    isLoadingChecklistTemplateDetail: false,
    isChecklistTemplateDetailVisible: false,
    isDeleteConfirmationVisible: false,
    deleteError: null,
  };

  componentDidMount() {
    this.getTemplates();
    this.getReportTemplates();
  }

  getTemplates = () => {
    if (this.state.isLoadingChecklistTemplates) {
      return;
    }
    this.setState({ isLoadingChecklistTemplates: true }, () => {
      Service.getTemplates()
        .then(response => {
          const checklistTemplates =
            response && response.data && response.data.data && response.data.data.checklist_templates ? response.data.data.checklist_templates : [];
          this.setState({ checklistTemplates, isLoadingChecklistTemplates: false });
        })
        .catch(err => {
          console.error("Error getting templates.", err);
          this.setState({ checklistTemplates: [], isLoadingChecklistTemplates: false });
          this.props.setAlert({ type: "error", title: err.message });
        });
    });
  };

  getTemplateDetail = selectedChecklistTemplate => {
    if (this.state.isLoadingChecklistTemplateDetail) return;

    this.setState({ isLoadingChecklistTemplateDetail: true }, () => {
      Service.getTemplateDetail({ id: selectedChecklistTemplate.checklist_template_id })
        .then(response => {
          const template = response && response.data && response.data.data ? response.data.data : {};
          this.setState({
            selectedChecklistTemplate: template,
            isLoadingChecklistTemplateDetail: false,
            isChecklistTemplateDetailVisible: true,
          });
        })
        .catch(err => {
          console.error("Error getting templates.", err);
          this.setState({ isLoadingChecklistTemplateDetail: false });
          this.props.setAlert({ type: "error", title: err.message });
        });
    });
  };

  createTemplate = () => {
    this.setState({ showError: false, submitting: true }, () => {
      Service.createTemplate({ name: this.state.newChecklistTemplateName })
        .then(response => {
          const id = response && response.data && response.data.data && response.data.data.id ? response.data.data.id : null;

          let { checklistTemplates } = this.state;
          if (id) {
            checklistTemplates.push({ name: this.state.newChecklistTemplateName, checklist_template_id: id });
          }

          this.setState({ checklistTemplates, submitting: false, isNewChecklistTemplateVisible: false });
          this.getTemplateDetail({ checklist_template_id: id });
        })
        .catch(err => {
          console.error("Error creating new template.", err);
          this.setState({ submitting: false });
          this.props.setAlert({ type: "error", title: err.message });
        });
    });
  };

  getReportTemplates = () => {
    if (this.state.isLoadingReportTemplates) {
      return;
    }
    this.setState({ isLoadingReportTemplates: true }, () => {
      Service.getReportTemplates({ dealer_id: this.props.globalState.selectedDealer.id })
        .then(response => {
          const reportTemplates = response && response.data && response.data.data ? response.data.data : [];
          this.setState({ reportTemplates, isLoadingReportTemplates: false });
        })
        .catch(err => {
          console.error("Error getting report templates.", err);
          this.setState({
            reportTemplates: [],
            isLoadingReportTemplates: false,
          });
          this.props.setAlert({ type: "error", title: err.message });
        });
    });
  };

  handleShowChecklistDetail = selectedChecklistTemplate => {
    this.getTemplateDetail(selectedChecklistTemplate);
  };

  handleHideChecklistDetail = () => {
    this.setState({ selectedChecklistTemplate: {}, isChecklistTemplateDetailVisible: false });
  };

  handleShowNewChecklist = () => {
    this.setState({
      isNewChecklistTemplateVisible: true,
      newChecklistTemplateName: "",
    });
  };

  handleHideNewChecklist = () => {
    this.setState({ isNewChecklistTemplateVisible: false });
  };

  saveNewTemplate = () => {
    if (this.state.newChecklistTemplateName) {
      this.createTemplate();
    } else {
      this.setState({ showError: true });
    }
  };

  handleUpdateChecklist = checklist => {
    if (checklist) {
      this.setState({ submitting: true }, () => {
        Service.updateTemplate(checklist)
          .then(result => {
            if (result && result.data && result.data.code && result.data.code === 200) {
              let { checklistTemplates } = this.state;
              let i = checklistTemplates.findIndex(c => c.checklist_template_id === checklist.id);
              if (i !== -1) {
                checklistTemplates[i].name = checklist.name;
              }

              this.setState({
                isChecklistTemplateDetailVisible: false,
                selectedChecklistTemplate: {},
                submitting: false,
                checklistTemplates,
              });
            }
          })
          .catch(err => {
            console.error("Error updating checklist.", err);
            this.props.setAlert({ type: "error", title: err.message });

            this.setState({ submitting: false });
          });
      });
    }
  };

  handleShowDeleteConfirmation = selectedChecklistTemplate => {
    this.setState({
      isDeleteConfirmationVisible: true,
      selectedChecklistTemplate,
      deleteError: null,
    });
  };

  handleHideDeleteConfirmation = () => {
    this.setState({
      isDeleteConfirmationVisible: false,
      selectedChecklistTemplate: {},
      deleteError: null,
    });
  };

  handleDeleteTemplate = () => {
    let { selectedChecklistTemplate, isLoadingChecklistTemplates } = this.state;

    if (isLoadingChecklistTemplates || !(selectedChecklistTemplate && selectedChecklistTemplate.checklist_template_id)) {
      return;
    }

    this.setState({ isLoadingChecklistTemplates: true, isDeletingChecklistTemplate: true }, () => {
      Service.deleteTemplate({ id: selectedChecklistTemplate.checklist_template_id })
        .then(result => {
          if (result && result.data && result.data.code && result.data.code === 200) {
            let { checklistTemplates } = this.state;
            let i = checklistTemplates.findIndex(c => c.checklist_template_id === selectedChecklistTemplate.checklist_template_id);
            if (i !== -1) {
              checklistTemplates.splice(i, 1);
            }

            this.setState({
              isDeleteConfirmationVisible: false,
              selectedChecklistTemplate: {},
              deleteError: null,
              isLoadingChecklistTemplates: false,
              isDeletingChecklistTemplate: false,
              checklistTemplates,
            });
          }
        })
        .catch(error => {
          let err = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0] : "Error deleting checklist";
          this.setState({ deleteError: err, isLoadingChecklistTemplates: false, isDeletingChecklistTemplate: false });
        });
    });
  };

  renderDeleteConfirm = () => {
    return (
      <CustomConfirm
        type="danger"
        isOpen={this.state.isDeleteConfirmationVisible}
        handleCancel={this.handleHideDeleteConfirmation}
        handleConfirm={() => this.handleDeleteTemplate()}
        isLoading={this.state.isDeletingChecklistTemplate}
        confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
        error={this.state.deleteError}
      />
    );
  };

  renderNewTemplate = () => {
    const { newChecklistTemplateName, submitting, showError } = this.state;
    return (
      <Modal
        open={true}
        onClose={() => {
          this.handleHideNewChecklist();
        }}
        closeOnDimmerClick={false}
        size="small"
      >
        <Modal.Header>{this.props.t("create_checklist_template").message || "Create Checklist Template"}</Modal.Header>

        <Modal.Content>
          <Form>
            <Form.Field>
              <UserInput
                error={showError && !newChecklistTemplateName}
                onChange={(e, data) => {
                  this.setState({ newChecklistTemplateName: data.value });
                }}
                value={newChecklistTemplateName}
                placeholder={this.props.t("name").message || "Name"}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            disabled={this.props.loading}
            onClick={() => {
              this.handleHideNewChecklist();
            }}
            floated="left"
          >
            <Icon name="close" /> {this.props.t("discard").message || "Discard"}
          </Button>
          <Button color="green" onClick={this.saveNewTemplate} loading={submitting} disabled={submitting}>
            <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  renderTable = () => {
    let { isLoadingChecklistTemplates, checklistTemplates } = this.state;
    let { t } = this.props;

    if (isLoadingChecklistTemplates) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
          {/* TODO: add translation key */}
          <section>{t("loading_checklist_templates").message || "Loading checklist templates"}</section>
        </div>
      );
    } else {
      return (
        <div className="TemplatesTable">
          <ReactTable
            className="ReactTable -floated-table -contained-large"
            data={checklistTemplates}
            sortable={false}
            resizable={false}
            showPagination={false}
            showPageSizeOptions={false}
            defaultPageSize={checklistTemplates.length}
            pageSize={checklistTemplates.length}
            noDataText={
              <div className="Table__no-results">
                <Icon disabled name="settings" style={{ fontSize: "1.75em", marginTop: "250px" }} />
                {/* TODO: add translation key */}
                <p>{t("no_checklist_templates").message || "No checklist templates"}</p>
              </div>
            }
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: e => {
                  if (column.id !== "delete") {
                    this.handleShowChecklistDetail(rowInfo.original);
                  }
                },
              };
            }}
            column={{
              ...ReactTableDefaults.column,
              headerClassName: "ReactTable__column-header",
              className: "ReactTable__column",
            }}
            columns={[
              {
                id: "name",
                Header: t("name").message || "Name",
                accessor: "name",
              },
              {
                id: "delete",
                Header: "",
                accessor: d => (
                  <Can I="delete_template" the="checklists">
                    <Label color="red" className="ChecklistDeleteLabel" onClick={() => this.handleShowDeleteConfirmation(d)}>
                      <Icon link name="trash" />
                    </Label>
                  </Can>
                ),
                maxWidth: 55,
              },
            ]}
          />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="ChecklistSettings page_inner_wrapper">
        <SubHeader>
          <Grid.Column width={9}>
            <h1>{this.props.t("checklist_settings").message || "Checklist Settings"}</h1>
          </Grid.Column>
          <Grid.Column width={2} floated="right">
            <Button color="green" icon labelPosition="right" onClick={this.handleShowNewChecklist} fluid>
              <Icon name="add" /> {this.props.t("add").message || "Add"}
            </Button>
          </Grid.Column>
        </SubHeader>

        {this.renderTable()}
        {this.state.isDeleteConfirmationVisible && this.renderDeleteConfirm()}
        {this.state.isNewChecklistTemplateVisible && this.renderNewTemplate()}
        {this.state.isChecklistTemplateDetailVisible && (
          <Detail
            checklist={this.state.selectedChecklistTemplate}
            reportTemplates={this.state.reportTemplates}
            loading={this.state.submitting}
            t={this.props.t}
            onHideDetail={this.handleHideChecklistDetail}
            onUpdate={this.handleUpdateChecklist}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChecklistSettings));
