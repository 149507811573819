import React, { Component } from "react";
import { Button, Icon, Modal, Segment, Grid, Header, Form, Checkbox } from "semantic-ui-react";
import { ColorPicker } from "../../components";

class Detail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      checklist: props.checklist,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checklist) this.setState({ checklist: nextProps.checklist });
  }

  handleInputChange = (e, data) => {
    let { checklist } = this.state;
    checklist[data.name] = data.value;
    this.setState({ checklist, submitted: false });
  };

  handleColorChange = (color, name) => {
    let { checklist } = this.state;
    checklist[name] = color;
    this.setState({ checklist, submitted: false });
  };

  handleCheckboxChange = (e, data) => {
    let { checklist } = this.state;
    checklist[data.name] = data.checked;
    this.setState({ checklist, submitted: false });
  };

  handleUpdate = () => {
    let { checklist } = this.state;
    const { onUpdate } = this.props;

    checklist.price = parseFloat(checklist.price);

    this.setState({ submitted: true }, () => {
      onUpdate(checklist);
    });
  };

  render() {
    const { reportTemplates, t } = this.props;
    const { submitted, checklist } = this.state;

    return (
      <div className="DealerDetail">
        <Modal
          open={true}
          onClose={() => {
            this.props.onHideDetail();
          }}
          closeOnDimmerClick={false}
          size="large"
        >
          <Modal.Header>{t("edit_checklist_template").message || "Edit Checklist Template"}</Modal.Header>

          <Modal.Content scrolling>
            <Grid>
              <Grid.Column width={6}>
                <Segment>
                  <Segment.Inline>
                    <Form>
                      <Form.Field>
                        <Form.Input
                          error={submitted && !(checklist && checklist.name.length > 0)}
                          onChange={this.handleInputChange}
                          defaultValue={checklist.name}
                          name="name"
                          label={t("name").message || "Name"}
                          placeholder={t("name").message || "Name"}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Form.Input
                          onChange={this.handleInputChange}
                          defaultValue={checklist.description}
                          name="description"
                          label={t("description").message || "Description"}
                          placeholder={t("description").message || "Description"}
                        />
                      </Form.Field>

                      <Form.Field>
                        <Form.Dropdown
                          placeholder={t("pdf_template").message || "PDF Template"}
                          options={
                            reportTemplates.map((a, key) => {
                              return { text: a.name, value: a.id, key };
                            }) || []
                          }
                          selection
                          value={checklist.pdf_layout_id}
                          name="pdf_layout_id"
                          label={t("pdf_template").message || "PDF Template"}
                          onChange={this.handleInputChange}
                        />
                      </Form.Field>
                    </Form>
                    <Header as="h5">
                      <Icon name="eye dropper" />
                      <Header.Content>{t("color_settings").message || "Color settings"}</Header.Content>
                    </Header>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={6}>Primary Color</Grid.Column>
                        <Grid.Column width={2}>
                          <ColorPicker color={checklist.primary_color} onChange={color => this.handleColorChange(color, "primary_color")} />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <hr />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>Text Color</Grid.Column>
                        <Grid.Column width={2}>
                          <ColorPicker color={checklist.text_color} onChange={color => this.handleColorChange(color, "text_color")} />
                        </Grid.Column>
                        <Grid.Column width={16}>
                          <hr />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={6}>Icon Color</Grid.Column>
                        <Grid.Column width={2}>
                          <ColorPicker color={checklist.icon_color} onChange={color => this.handleColorChange(color, "icon_color")} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment.Inline>
                </Segment>
              </Grid.Column>
              <Grid.Column width={10}>
                <Segment>
                  <Header as="h5">
                    <Icon name="settings" />
                    <Header.Content>{t("global_settings").message || "Global settings"}</Header.Content>
                  </Header>
                  <Segment.Inline>
                    <Form className="checklist-settings-form">
                      <Form.Field>
                        <Checkbox
                          checked={checklist.multi_use_enabled}
                          name="multi_use_enabled"
                          label={t("multi_use_enabled").message || "Multi-use enabled"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          checked={checklist.hide_timestamp}
                          name="hide_timestamp"
                          label={this.props.t("hide_check_timestamp").message || "Do not show timestamps"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          checked={checklist.can_skip_km_and_date}
                          name="can_skip_km_and_date"
                          label={this.props.t("allow_to_skip_km_and_date").message || "Allow to skip mileage and next date"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          checked={checklist.default_only}
                          name="default_only"
                          label={t("default_only").message || "Default only"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          checked={checklist.skip_update_appointment_status}
                          name="skip_update_appointment_status"
                          label={this.props.t("skip_update_appointment_status").message || "Skip updating appointment status"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Checkbox
                          checked={checklist.add_purpose}
                          name="add_purpose"
                          label={t("add_purpose").message || "Add purpose"}
                          onChange={this.handleCheckboxChange}
                        />
                      </Form.Field>
                    </Form>
                  </Segment.Inline>
                </Segment>

                <Segment>
                  <Header as="h5">
                    <Icon name="euro sign" />
                    <Header.Content>{t("price_settings").message || "Price settings"}</Header.Content>
                  </Header>
                  <Segment.Inline>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Checkbox
                            checked={checklist.price_enabled}
                            name="price_enabled"
                            label={t("price_enabled").message || "Price enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment.Inline>
                </Segment>

                <Segment>
                  <Header as="h5">
                    <Icon name="clipboard check" />
                    <Header.Content>{t("status_settings").message || "Status settings"}</Header.Content>
                  </Header>
                  <Segment.Inline>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.customer_ok_enabled}
                            name="customer_ok_enabled"
                            label={t("customer_ok_enabled").message || "Customer OK enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.ready_enabled}
                            name="ready_enabled"
                            label={t("ready_enabled").message || "Ready enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment.Inline>
                </Segment>

                <Segment>
                  <Header as="h5">
                    <Icon name="list" />
                    <Header.Content>{t("question_settings").message || "Question settings"}</Header.Content>
                  </Header>
                  <Segment.Inline>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.snooze_enabled}
                            name="snooze_enabled"
                            label={t("snooze_enabled").message || "Snooze enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.pin_enabled}
                            name="pin_enabled"
                            label={t("pin_enabled").message || "Pin enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Checkbox
                            name="cc_default_selected"
                            defaultChecked={checklist.cc_default_selected}
                            label={t("pre_selected_on_customer_communications").message || "Pre-selected on customer communication"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Checkbox
                            name="cc_default_selected_nvt"
                            defaultChecked={checklist.cc_default_selected_nvt}
                            label={t("nvt_preselected_on_customer_communications").message || "NVT pre-selected in customer communication"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Checkbox
                            name="show_mechanic_name"
                            defaultChecked={checklist.show_mechanic_name}
                            label={t("show_mechanic_name").message || "Show mechanic name"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>
                        <Form.Field />
                      </Form.Group>
                    </Form>
                  </Segment.Inline>
                </Segment>

                <Segment>
                  <Header as="h5">
                    <Icon name="clipboard list" />
                    <Header.Content>{t("report_settings").message || "Report settings"}</Header.Content>
                  </Header>
                  <Segment.Inline>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.include_in_reports}
                            name="include_in_reports"
                            label={t("export_to_reporting").message || "Export to reporting"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>

                        <Form.Field>
                          <Checkbox
                            defaultChecked={checklist.total_pdf_enabled}
                            name="total_pdf_enabled"
                            label={t("total_pdf_enabled").message || "Total PDF enabled"}
                            onChange={this.handleCheckboxChange}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Form>
                  </Segment.Inline>
                </Segment>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => {
                this.props.onHideDetail();
              }}
              floated="left"
            >
              <Icon name="close" /> {this.props.t("close").message || "Close"}
            </Button>
            <Button color="green" onClick={this.handleUpdate} loading={this.props.loading} disabled={this.props.loading}>
              <Icon name="checkmark" /> {this.props.t("save").message || "Save"}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default Detail;
